import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/styles'
import Spinner from 'react-md-spinner'


const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(5),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
}))

const PageLoader = ({ delay, minHeight }) => {

  const classes = useStyles()
  const theme = useTheme()
  const [visible, setVisible] = useState(delay ? false : true)

  const style = minHeight
    ? { minHeight }
    : {}

  useEffect(() => {
    if (delay) {
      setTimeout(() => {
        setVisible(true)
      }, delay)
    }
  }, [delay])

  return (
    <div
      className={classes.root}
      style={style}>
      {visible && (
        <Spinner singleColor={theme.palette.dark} />
      )}
    </div>
  )
}

PageLoader.propTypes = {
  delay: PropTypes.number,
  minHeight: PropTypes.number,
}

export default PageLoader
