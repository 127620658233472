import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import MDSpinner from 'react-md-spinner'


const useStyles = makeStyles(theme => ({
  center: {    
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: props => props.minHeight ? props.minHeight : '200px'
  },
}))

const SimpleModal = ({ minHeight }) => {
  const classes = useStyles({ minHeight })

  return (
    <div className={classes.center}>
      <MDSpinner singleColor="#222E3C" />
    </div>
  )
}

SimpleModal.propTypes = {
  minHeight: PropTypes.string,

}

export default SimpleModal