

import React from 'react'
import { makeStyles, useTheme } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Typography, Grid } from '@material-ui/core'
import clsx from 'clsx'
import ServiceCard from './service-card'

// icons
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects'
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid'
import LanguageIcon from '@material-ui/icons/Language'
import StorageIcon from '@material-ui/icons/Storage'

const useStyles = makeStyles(theme => ({
  root: {},
  container: {
    minHeight: '100vh',
    backgroundColor: '#FFF',
    padding: '200px 100px 0 200px',
    [theme.breakpoints.down('lg')]: { padding: '200px 100px 0 100px' },
    [theme.breakpoints.down('md')]: { padding: '100px 20px 0 30px' },
    [theme.breakpoints.down('sm')]: { padding: '20px 20px 0 30px' },
  },
  services: {
    [theme.breakpoints.down('xs')]: { marginBottom: '50px' },
  },
  cardContainer: {
    // backgroundColor: 'red',
    maxWidth: 550,
    marginTop: 0,
    [theme.breakpoints.down('md')]: { margin: '60px auto' },
    [theme.breakpoints.down('xs')]: { marginTop: 0, marginLeft: -17 }
  },
  card: {
    width: '250px',
  },
  leftCard: {
    marginTop: -80,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  rightCard: {
    marginTop: -40,
    [theme.breakpoints.down('sm')]: {
      marginTop: 40,
    },
  },
  thirdCard: {
    [theme.breakpoints.down('sm')]: { marginTop: 0 },
  },
  fourthCard: {
    [theme.breakpoints.down('sm')]: { marginTop: -40 },
    [theme.breakpoints.down('xs')]: { marginTop: 0 },
  },
  text: {
    marginBottom: theme.spacing(1),
    maxWidth: 500,
    [theme.breakpoints.down(1200)]: {
      maxWidth: '100%',
      textAlign: 'center',
      padding: '0 20px',
    },
  },
  smallText: {
    marginTop: theme.spacing(6),
    textTransform: 'uppercase',
    fontSize: '16px',
    fontWeight: 700,
    color: '#90acd1',
    letterSpacing: '4px',
  },
  buttonContainer: {
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    }
  },
}))

const Sevices = () => {

  const theme = useTheme()
  const classes = useStyles()
  const down1200 = useMediaQuery(theme.breakpoints.down(1200))

  return (
    <Grid container spacing={3} className={classes.container}>
      <Grid item xs={12} sm={12} md={down1200 ? 12 : 6} >
        <Typography className={clsx(classes.text, classes.smallText)}>
          My Tech Stack
        </Typography>
        <Typography variant="h3" className={classes.text} style={{ fontWeight: 'bold' }}>
          I can make this awesome things
        </Typography>
        <Typography variant="body1" className={classes.text}>
          I understand what your business means to you, your requirements considering trends.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={down1200 ? 12 : 6} className={classes.services}>
        
        <Grid container spacing={3} className={classes.cardContainer}>
          {[
            { label: 'Web Development', Icon: LanguageIcon, description: 'React, ASP.NET Core, NodeJS, NextJS, PHP' },
            { label: 'Mobile Development', Icon: PhoneAndroidIcon, description: 'React Native' },
            { label: 'UI Design', Icon: EmojiObjectsIcon, description: 'Material UI, Bootstrap 5' },
            { label: 'Database', Icon: StorageIcon, description: 'SQL Server, MongoDB, MySQL, PostgreSQL' },
          ].map(({ label, Icon, description }, index) => (
            <Grid key={index} item xs={12} sm={6} 
              className={
                clsx(
                  classes.card,
                  index % 2 !== 0 ? classes.leftCard : classes.rightCard,
                  index === 2 ? classes.thirdCard : '',
                  index === 3 ? classes.fourthCard : '',
                )}>
                <ServiceCard
                  label={label}
                  Icon={Icon}
                  description={description}
                />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Sevices
