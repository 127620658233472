import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Paper, Input } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'


const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(-1)
  },
  search: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    height: 42,
    padding: theme.spacing(0, 2),
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.icon
  },
  searchInput: {
    flex: 1
  },
  searchButton: {
    marginLeft: theme.spacing(2)
  }
}))

const Search = ({ onChange }) => {

  const classes = useStyles()
  const field = useRef()

  const onFocus = () =>
    field.current && field.current.focus()

  return (
    <div
      className={classes.root}
      onClick={onFocus}>
      <Paper
        className={classes.search}
        elevation={1}>
        <SearchIcon className={classes.searchIcon} />
        <Input
          className={classes.searchInput}
          disableUnderline
          inputRef={field}
          placeholder="Search"
          onChange={(e) => onChange(e.target.value)} />
      </Paper>
    </div>
  )
}

Search.propTypes = {
  onChange: PropTypes.func
}

export default Search
