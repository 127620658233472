import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Card, CardContent, Typography, Grid } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    margin: 20,
    width: '100%',
    height: 250, // 285
    borderRadius: '16px',
    boxShadow: '5px 8px 24px 5px rgba(208, 216, 243, 0.6)',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
  icon: {
    height: 50,
    width: 'auto',
    paddding: '1000px',
    marginTop: 20,
  },
}))

const ServiceCard = ({ Icon, label, description }) => {

  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container spacing={3} className={classes.container}>
          <Grid item xs={12} style={{ display: 'flex', justifyContent:'center', paddingTop: 50 }}>
            <Icon className={classes.icon} />
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', justifyContent:'center' }}>
            <Typography variant="h6" color="primary">
              {label}
            </Typography>
          </Grid>
          {/* <Grid item xs={12} style={{ display:'flex', justifyContent: 'center', textAlign:'center' }}>
            <Typography color="textSecondary">
              {description}
            </Typography>
          </Grid> */}
        </Grid>
      </CardContent>
    </Card>
  )
}

export default ServiceCard