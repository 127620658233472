import React, { memo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Grid, Divider, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(-2),
  },
  label: {
    padding: theme.spacing(1, 0, 1, 1),
    minWidth: '100px',
    fontWeight: 'bold',
    // fontSize: '14px',
  },
  responsiveLabel: {
    padding: theme.spacing(2, 0, 1, 0),
    minWidth: '100px',
    fontWeight: 'bold',
    // fontSize: '14px',
  },
  children: {
    padding: theme.spacing(3),
  },
  topPadding: {
    paddingTop: theme.spacing(3)
  }
}))

const RowValueText = ({ label, children, responsive, responsiveLabel, labelClassName }) => {

  const classes = useStyles()
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <>
      {responsive && mobileView ? (
        <Typography variant="body1">
          {responsiveLabel && (
            <>
              <Typography className={classes.responsiveLabel} variant="h6">
                {label}
              </Typography>
              <Divider />
              <br />
            </>
          )}
          {children}
        </Typography>
      ) : (
        <>
          <Grid container className={classes.container}>
            <Grid item sm={4} xs={5}>
              <Typography className={clsx(classes.label, labelClassName ? labelClassName : responsive ? classes.topPadding : '')} variant="body1">
                {label}
              </Typography>
            </Grid>
            <Grid item sm={8} xs={responsive ? 12 : 7} className={classes.children}>
              {children}
            </Grid>
          </Grid>
          <Divider />
        </>
      )}
    </>
  )
}

RowValueText.propTypes = {
  label: PropTypes.string,
  children: PropTypes.any,
  responsive: PropTypes.bool,
  responsiveLabel: PropTypes.bool,
  labelClassName: PropTypes.string,
}

export default memo(RowValueText)
