import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Fade } from '@material-ui/core'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Skeleton from '@material-ui/lab/Skeleton'

import { SRLWrapper } from "simple-react-lightbox"


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  image: {
    margin: theme.spacing(0, 2, 2, 0),
    width: 90,
    height: 90,
    objectFit: 'cover',
    borderRadius: 4,
    '&:hover': {
      opacity: 0.8,
      cursor: 'zoom-in'
    },
  },
  hidden: {
    display: 'none'
  },
  placeholder: {
    margin: theme.spacing(0, 2, 2, 0),
    borderRadius: 4,
    objectFit: 'cover',
  },
}))


const ImagePlaceholder = () => {
  const classes = useStyles()

  return (
    <Skeleton
      className={classes.placeholder}
      variant="rect"
      width={90}
      height={90}
    />
  )
}

const LightboxWrapper = ({ attachments, isLoading = false }) => {
  const classes = useStyles()

  const [loaded, setLoaded] = React.useState(new Array(attachments.length - 1).fill(false))

  // const [photoIndex, setPhotoIndex] = React.useState(0)

  const imageLoaded = key => {
    setTimeout(() => {
      setLoaded([
        loaded[key] = true,
        ...loaded
      ])
    }, 1000)
  }

  if(isLoading && attachments && attachments.length > 0) {
    return (
      <div className={classes.root}>
        {new Array(attachments.length).map(() => <ImagePlaceholder />)}
      </div>
    )
  }

  return (
    <SRLWrapper options={{
      buttons: {
        showAutoplayButton: false,
        showDownloadButton: true,
        showThumbnailsButton: false,
      }
    }}>
      <div className={classes.root}>
        {attachments && attachments.length > 0 ? attachments.map((file, key) => (
          <div key={key}>
            {!loaded[key] && <ImagePlaceholder />}
            <Fade in={loaded[key]}>
              <LazyLoadImage
                className={loaded[key] ? classes.image : classes.hidden}
                alt={file?.name || `Screenshot ${key}`}
                onLoad={() => imageLoaded(key)}
                height={60}
                width={60}
                src={file?.url || file}
                // onClick={() => {
                //   setPhotoIndex(key)
                // }}
              />
            </Fade>
          </div>
        )) : (
          '-'
        )}
      </div>
    </SRLWrapper>
  )
}

LightboxWrapper.propTypes = {
  attachments: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
}

export default LightboxWrapper
