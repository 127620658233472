

import React from 'react'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, AppBar, Toolbar } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 500,
  },
  footer: {
    background: '#90acd3',
  },
  footerText: {
    width: '100%',
    alignItems: 'center',
  },
}))

const Footer = () => {
  const classes = useStyles()

  return (     
    <div className={useStyles.root}>
      <AppBar position="static" className={classes.footer}>
        <Toolbar>
          <Typography gutterBottom align="center" className={classes.footerText}>
            Kayee {moment().format('YYYY')} ~ Made with ❤️ and ☕.
            </Typography>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default Footer
