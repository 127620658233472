import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Button, Grid, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 0, 4, 0),
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttonArea: {
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(-3, 0, -1, 0),
    },
  },
  subtitle: { marginLeft: 5 }
}))

const PageHeader = ({
  className,
  breadcrumbs,
  title,
  titleVariant,
  subtitle,
  loading,

  primaryButton,
  secondaryButton,
  ...rest
}) => {

  const classes = useStyles()
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid
        alignItems="flex-end"
        container
        justify="space-between"
        spacing={3}>
        <Grid item>
          <Typography component="h2" gutterBottom variant="overline">
            {/* {breadcrumbs} */}
            {' '}
          </Typography>
          <Typography component="h1" variant={titleVariant || 'h4'}>
            {title}
          </Typography>
          {subtitle && (
            <Typography component="h1" variant="body1" className={classes.subtitle}>
              {subtitle}
            </Typography>
          )}
        </Grid>
        <Grid item className={classes.buttonArea}>
          
          {/* Secondary Button (Left) */}
          {secondaryButton && secondaryButton.action && !secondaryButton.hide && (
            <Button
              fullWidth={mobileView}
              disabled={secondaryButton.disable}
              id="secondary-action"
              data-cy="secondary-action"
              className={classes.button}
              color={secondaryButton.color || 'secondary'}
              variant="contained"
              onClick={secondaryButton.action}>
              {secondaryButton.label}
            </Button>
          )}
          
          {/* Primary Button (Right) */}
          {primaryButton && primaryButton.action && !primaryButton.hide && (
            <Button
              fullWidth={mobileView}
              disabled={primaryButton.disable}
              id="primary-action"
              data-cy="primary-action"
              className={classes.button}
              color={primaryButton.color || 'primary'}
              variant="contained"
              onClick={primaryButton.action}>
              {primaryButton.label}
            </Button>
          )}
        </Grid>
      </Grid>
      
    </div>
  )
}

PageHeader.propTypes = {
  className: PropTypes.string,
  breadcrumbs: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleVariant: PropTypes.string,
  loading: PropTypes.bool,

  primaryButton: PropTypes.shape({
    label: PropTypes.string,
    action: PropTypes.func,
    hide: PropTypes.bool,
    disable: PropTypes.bool,
  }),

  secondaryButton: PropTypes.shape({
    label: PropTypes.string,
    action: PropTypes.func,
    color: PropTypes.string,
    hide: PropTypes.bool,
    disable: PropTypes.bool,
  }),
}

export default PageHeader
