import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Button } from '@material-ui/core'
import clsx from 'clsx'
import GalleryItem from './gallery-item'
import { projects } from './config'

const useStyles = makeStyles(theme => ({
  root: {
    margin: '20px 0 100px 0',
  },
  tagContainer: {
    position: 'relative',
  },
  container: {
    minBlockSize: 600,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  button: {
    margin: theme.spacing(0, 2, 2, 2),
    letterSpacing: '1px',
    fontSize: '16px',
    fontWeight: 400,
    marginTop: theme.spacing(4),
    padding: theme.spacing(1, 6),
    borderRadius: '25px',
    backgroundColor: '#90acd3',
    '& span': { color: '#FFF' },
    '&:hover': { backgroundColor: '#25557c' },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      padding: theme.spacing(1, 2),
      margin: theme.spacing(1),
    },
  },
  active: { background: '#25557c' },
}))

const defaultfilters = [
  { name: 'All', status: true },
  { name: 'Web App', status: false },
  { name: 'App', status: false },
  { name: 'Other', status: false },
]

const Gallery = () => {
  
  const classes = useStyles()
  const [images, setImages] = useState(projects)
  const [filters, setFilters] = useState(defaultfilters)

  const onFilter = (key) => () => {
    setFilters(filters.map(item => item.name === key ? { ...item, status: true } : { ...item, status: false }))
    setImages(key === 'All' ? projects : projects.filter(item => item.tag === key))
  }

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} className={classes.tagContainer}>
        {filters.map(({ name, status }, index) => (
          <Button
            key={`project-filter-${index}`}
            className={clsx(classes.button, status ? classes.active : '')}
            variant="contained"
            color={'primary'}
            onClick={onFilter(name)}
          >
            {name}
          </Button>
        ))}
      </Grid>
      <Grid item xs={12} className={classes.container}>
        {images.map((project, key) => (
          <div key={`project-${key}`}>
            <GalleryItem
              {...project}
            />
          </div>
        ))}
      </Grid>
    </Grid>
  )
}

export default Gallery
