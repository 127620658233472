import React from 'react'
import { Switch, Route } from 'react-router-dom'
import * as constants from './constants'
import LandingPage from 'pages/landing-page' 

const Routes = () => (
  <Switch>
    <Route path={constants.ROOT_PATH} component={LandingPage} exact />
  </Switch>
)
export default Routes