

import React  from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import Gallery from './gallery'


const useStyles = makeStyles(theme => ({
  root: {},
  container: {
    textAlign: 'center',
    // minHeight: '100vh',
    backgroundColor: '#f9f9ff',
    padding: '100px 100px 20px 200px',
    [theme.breakpoints.down('lg')]: { padding: '100px 100px 20px 100px' },
    [theme.breakpoints.down('md')]: { padding: '100px 20px 20px 30px' },
  },
  title: {
    fontWeight: 'bold', marginTop: 180,
    [theme.breakpoints.down('md')]: { marginTop: 100, },
    [theme.breakpoints.down('sm')]: { marginTop: -150, },
  },
  text: {
    marginBottom: theme.spacing(1),
    maxWidth: 500,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      textAlign: 'center',
      padding: '0 20px',
    },
  },
  image: {
    borderRadius: '100px',
    width: '70%',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: 300,
      height: 'auto',
    }
  },
  buttonContainer: {
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    }
  },
  smallText: {
    textTransform: 'uppercase',
    fontSize: '16px',
    fontWeight: 700,
    color: '#90acd1',
    letterSpacing: '4px',
    textAlign: 'center',
  },
}))

const AboutMe = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div id="aboutme" />
        <Typography className={classes.smallText}>
          My Awesome Work
        </Typography>
        <Typography variant="h3">
          Projects
        </Typography>
        <Gallery />
    </div>
  )
}

export default AboutMe
