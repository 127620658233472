


export const projects = [
  {
    projectTitle: 'Frank Energy Website',
    shortDescription: 'Customer facing website for Frank Energy',
    description: 'Help migrated the old Frank Energy’s website from .NET kentico CMS to more modern tech stack - NextJS and AgilityCMS',
    tag: 'Web App',
    duration: 'Nov 2022 to Present',
    responsibilities: [
      'Frontend',
      'CMS',
      'API Integration',
    ],
    stack: ['NextJS', 'AgilityCMS', 'TailwindCSS', 'Framer Motion'],
    src: 'images/works/frank-energy.jpg',
    link: 'https://www.frankenergy.co.nz',
    screenshots: [
      'images/works/frank-energy.gif',
    ],
  },
  {
    projectTitle: 'Banana Pinyin App - Karaoke Companion App',
    shortDescription: 'A personal tool to read Chinese lyrics inorder to sing Karaoke with friends',
    description: 'A Chinese lyrics translation tool with romanization and word-by-word alignment. Also integrated with a song lyrics API for quick Chinese song lyric lookup.',
    tag: 'App',
    duration: 'April 2023 to Present',
    responsibilities: [
      'Frontend',
      'Backend',
      'API Integration',
      'App Store Maintenance',
    ],
    stack: ['React Native Expo', 'NodeJS'],
    src: 'images/works/personal/banana-pinyin1.png',
    link: 'https://play.google.com/store/apps/details?id=kayeew.bananapinyin',
    screenshots: [
      'images/works/personal/banana-pinyin.jpg',
      'images/works/personal/banana-pinyin2.jpg',
      'images/works/personal/banana-pinyin3.jpg',
    ],
  },
  {
    projectTitle: 'Toyota Fleet Management Portal',
    shortDescription: 'Internal Web based tool for Toyota Fleet Managers to managed leased vehicles',
    description: 'Fleet managers need to keep track of hundreds of cars, in multiple locations, all at various stages of their lease life. '
      + 'This is often a very manual, slow and time heavy way of managing fleets and centralise the information to a dynamic portal. '
      + 'The solution provides a clean, efficient dashboard and multiple reports that hold all the information a fleet manager could need, at the press of a button.',
    tag: 'Web App',
    duration: 'March 2020 to Nov 2022',
    responsibilities: [
      'Frontend',
      'Backend',
      'API Integration',
    ],
    stack: ['ASP.NET Core', 'MS SQL Server', 'Azure', 'Auth0', 'ReactJS', 'RxJS', 'Redux', 'Material UI', 'SendGrid', 'Cypress'],
    src: 'images/works/born/toyota.jpg',
    link: 'https://www.borndigital.co.nz/our-work/vehicle-fleet-management-system/?cf=software',
    screenshots: [
      'images/works/born/toyota.jpg',
      'images/works/born/toyota.gif',
      'images/works/born/toyota2.jpg',
      'images/works/born/toyota3.jpg',
      'images/works/born/toyota4.jpg'
    ],
  },
  {
    projectTitle: 'Toyota MyDrive App',
    shortDescription: 'Toyota Leased Vehicle Driver Companion App',
    description: 'Placing Fleet vehicle lifecycle management in your hands with MyDrive by TFM. '
      + 'The MyDrive app provides drivers with a holistic view of their vehicle for the duration if its lifecycle. '
      + 'Whether you are looking to keep up to date with general health and safety obligations, manage incidents or simply update vehicle mileage, '
      + 'the interactive dashboards and in-life notifications will ensure important events are always visible to you. '
      + 'Direct integration with the ToyotaFleet Portal ensures that all updates recorded in app are transferred and stored in your ToyotaFleet Portal account, '
      + 'helping you stay on top of managing your fleet.',
    tag: 'App',
    // status: 'Ongoing',
    duration: 'February 2021 - Nov 2022',
    responsibilities: [
      'Been on the project for over a year',
      'Implemented and refactored most of the frontend since its early stages',
      'Helped extend various API endpoints',
    ],
    stack: ['React Native', 'Expo', 'Typescript', 'Redux', 'ASP.NET Core', 'MS SQL Server', 'Firebase Notifications', 'Auth0'],
    src: 'images/works/born/toyota-app.png',
    link: 'https://www.borndigital.co.nz/our-work/vehicle-fleet-management-system/?cf=software',
    screenshots: ['images/works/born/toyota-app.png']
  },
  {
    projectTitle: 'Safetywise (Workplace Health & Safety PWA)',
    shortDescription: 'Workplace Safety & Compliance Portal',
    description: 'Safetywise is a comprehensive online tool for managing workplace safety and compliance. The PWA solution offers native app like experience with offine mode for easy for Incident/Accident recording, Induction Meetings, Site Specific Safety Plan Checklists, Safety Policy, Hazard and Hazardous Substance Libraries, Customers and Sites, Timesheets.',
    tag: 'Web App',
    // status: 'No longer on the project',
    duration: 'February 2021 - Present',
    responsibilities: [
      'Frontend',
      'Backend',
      'API Integration',
      'Datebase Design',
    ],
    stack: ['ASP.NET Core', 'MS SQL Server','ReactJS', 'RxJS', 'Redux', 'PWA', 'SendGrid', 'Material UI' , 'Stripe API', 'Cypress'],
    src: 'images/works/born/safetywise-cover.png',
    link: 'https://safetywise.co.nz/',
    screenshots: [
      'images/works/born/safetywise.gif',
      'images/works/born/safetywise1.png',
      'images/works/born/safetywise3.png',
      'images/works/born/safetywise4.png',
      'images/works/born/safetywise5.png',
      'images/works/born/safetywise6.png'
    ]
  },
  {
    projectTitle: 'EdPro - School Ticketing Portal',
    shortDescription: 'Property Maintenance and Management Ticketing System for Schools',
    description: 'Simple Ticketing Portal for Schools to track and manage contractors, products, property maintenance services.',
    tag: 'Web App',
    // status: 'Ongoing',
    duration: 'May 2021 - Present',
    responsibilities: [
      'Frontend',
      'API Integration',
      'Backend',
      'Datebase Design',
    ],
    stack: ['Razor Pages', 'ASP.NET Core', 'MS SQL', 'Bootstrap 5'],
    src: 'images/works/born/edpro1.png',
    screenshots: [
      'images/works/born/edpro.gif',
      'images/works/born/edpro1.png',
      'images/works/born/edpro.png',
      'images/works/born/edpro2.png'
    ]
  },
  {
    projectTitle: 'PlantATree (Dummy Tree Store)',
    shortDescription: 'E-Commerce website that sells trees (University Project)',
    description: 'Univeristy project for learning the MERN Stack',
    tag: 'Web App',
    // status: 'Archived',
    duration: 'May - June 2019',
    stack: ['ReactJS', 'NodeJS', 'ExpressJS', 'Mongo DB', 'Stripe API'],
    src: 'images/works/personal/plantatree.gif',
    link: 'https://github.com/kayeew/PlantATree_Tree-cky',
    screenshots: ['images/works/personal/plantatree.gif', 'images/works/personal/plantatree.png']
  },
  {
    projectTitle: 'Cabs Online (Simple Booking Site)',
    shortDescription: 'Simple Taxi Booking Website',
    description: 'Univeristy project for a web developement paper',
    tag: 'Website',
    // status: 'Archived',
    duration: 'May - June 2019',
    stack: ['PHP', 'AJAX', 'MySQL', 'Bootstrap'],
    src: 'images/works/personal/cabsonline.gif',
    link: 'https://github.com/kayeew/CabsOnline',
    screenshots: ['images/works/personal/cabsonline.gif', 'images/works/personal/cabsonline.png']
  },
  // {
  //   projectTitle: 'Church Wesbite',
  //   shortDescription: 'Single Page Website',
  //   description: 'Simple one page website for a church youth group that I made in 1 day to practice/challenge my web dev skills.',
  //   tag: 'Web App',
  //   status: 'Archived',
  //   duration: 'December 2019',
  //   stack: ['HTML', 'CSS', 'Bootstrap'],
  //   src: 'images/works/personal/acmcc.gif',
  //   link: '',
  //   screenshots: ['images/works/personal/acmcc.gif']
  // },
  {
    projectTitle: 'Fast Food Coupon Scrapper',
    shortDescription: 'Web Scrapper to scrap food coupons/deals from various fast food chain websites',
    description: 'Personal side project to learn web scrapping with NodeJS and to the scrap best lunch deals during my university days',
    tag: 'Other',
    // status: 'Archived',
    duration: 'June 2019',
    stack: ['NodeJS', 'ExpressJS', 'CheerioJS', 'Bootstrap'],
    src: 'images/works/personal/couponscrapper.gif',
    link: 'https://github.com/kayeew/coupon-scrapper',
    screenshots: ['images/works/personal/couponscrapper.gif']
  },
]