import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/styles'
import theme from 'theme'
import Routes from './routes'
import SimpleReactLightbox from 'simple-react-lightbox'

const App = () => {

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <SimpleReactLightbox>
          <Routes />
        </SimpleReactLightbox>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
