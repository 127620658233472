

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, IconButton } from '@material-ui/core'
import clsx from 'clsx'
import { BuyMeCoffeeButton } from 'components'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import GitHubIcon from '@material-ui/icons/GitHub'

const useStyles = makeStyles(theme => ({
  root: {},
  container: {
    // marginTop: theme.spacing(12),
    // minHeight: '100vh',
    backgroundColor: '#FFF',
    padding: '100px 100px 100px 200px',
    [theme.breakpoints.down('lg')]: { padding: '100px 100px 100px 100px' },
    [theme.breakpoints.down('md')]: { padding: '20px 20px 100px 30px' },
    [theme.breakpoints.down('sm')]: { padding: '20px 20px 100px 30px' },
    [theme.breakpoints.down('xs')]: { padding: '20px 20px 100px 30px' },
  },
  title: {
    fontWeight: 'bold', marginTop: 180,
    [theme.breakpoints.down('lg')]: { marginTop: 100, },
    [theme.breakpoints.down('md')]: { marginTop: 50, },
    [theme.breakpoints.down('sm')]: { marginTop: 0, },
    [theme.breakpoints.down('xs')]: { marginTop: 0, },
  },
  text: {
    marginBottom: theme.spacing(1),
    maxWidth: 500,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      textAlign: 'center',
      padding: '0 20px',
    },
  },
  image: {
    borderRadius: '100px',
    width: '70%',
    height: 'auto',
    [theme.breakpoints.down('sm')]: { width: 300, height: 'auto' },
  },
  smallText: {
    marginTop: theme.spacing(6),
    textTransform: 'uppercase',
    fontSize: '16px',
    fontWeight: 700,
    color: '#90acd1',
    letterSpacing: '4px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'left',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    }
  },
  iconButton: { marginRight: 10 },
  buyMeCoffeeButton: {
    display: 'flex',
    justifyContent: 'left',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: { justifyContent: 'center' },
  }
}))

const AboutMe = () => {
  const classes = useStyles()

  return (
    <Grid container spacing={3} className={classes.container}>
      <div id="aboutme" />
      <Grid item xs={12} sm={12} md={6} style={{ textAlign: 'center' }}>
        <img className={classes.image} src="images/avatar.png" alt="Kayee" />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Typography variant="h3" className={clsx(classes.text, classes.title)}>
          About Me
        </Typography>
        <Typography variant="body1" className={classes.text}>
          I have a 🔥Passion for Creating ✨Clean, 🌸Beautiful, Interactive, Minimalistic, Responsive and User Friendly UI. 
        </Typography>
        <br />
        <Typography variant="body1" className={classes.text}>
          Lets 🤝 connect or have ☕ coffee.
        </Typography>
        
        <div className={classes.buttonContainer}>
          <IconButton
            className={classes.iconButton}
            color="inherit"
            aria-label="LinkedIn"
            edge="start"
            target="_top"
            rel="noopener noreferrer"
            href={`https://www.linkedin.com/in/kayee-wong`}
          >
            <LinkedInIcon style={{ fontSize: 30 }}/>
          </IconButton>
          
          <IconButton
            className={classes.iconButton}
            color="inherit"
            aria-label="GitHub"
            edge="start"
            target="_top"
            rel="noopener noreferrer"
            href={`https://github.com/kayeew`}
            
          >
            <GitHubIcon />
          </IconButton>
        </div>
        
        <div className={classes.buyMeCoffeeButton}>
          <BuyMeCoffeeButton />
        </div>
      </Grid>
    </Grid>
  )
}

export default AboutMe
