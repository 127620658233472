

import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Button } from '@material-ui/core'
import clsx from 'clsx'
import { init } from 'ityped'
import { HorizontalNavBar } from 'components'

// pages
import Services from './services'
import Projects from './projects'
import AboutMe from './about-me'
import Footer from './footer'

const useStyles = makeStyles(theme => ({
  root: {
    overflowX: 'hidden',
  },
  container: {
    // backgroundImage: 'url("background_typography/hi_there.png")',
    // backgroundRepeat: 'no-repeat',
    // backgroundSize: '65%',
    // backgroundPosition: 'left top',
    // marginTop: theme.spacing(12),
    minHeight: '100vh',
    backgroundColor: '#f9f9ff',
    padding: '270px 100px 0 200px',
    [theme.breakpoints.down('lg')]: { padding: '270px 100px 0 100px' },
    [theme.breakpoints.down(1200)]: { padding: '270px 100px 0 100px' },
    [theme.breakpoints.down('md')]: { padding: '270px 20px 0 30px' },
    [theme.breakpoints.down('sm')]: { padding: '100px 20px 0 30px' },
  },
  text: {
    marginBottom: theme.spacing(1),
    maxWidth: 500,
    textAlign: 'left',
    [theme.breakpoints.down('md')]: { maxWidth: '100%', textAlign: 'left' },
    [theme.breakpoints.down('sm')]: { textAlign: 'center' }
  },
  image: {
    borderRadius: '300px',
    width: '500px',
    height: '475px',
    marginBottom: 0,
    [theme.breakpoints.down('md')]: {
      width: 450,
      height: 'auto',
      marginBottom: 50,
    },
    [theme.breakpoints.down('sm')]: {
      width: 400,
      height: 'auto',
      marginBottom: 50,
    },
    [theme.breakpoints.down('xs')]: {
      width: 300,
      borderRadius: '100px',
      height: 'auto',
    },
  },
  smallText: {
    marginTop: theme.spacing(8),
    textTransform: 'uppercase',
    fontSize: '16px',
    fontWeight: 700,
    color: '#90acd1',
    letterSpacing: '4px',
    [theme.breakpoints.up(1200)]: {
      marginTop: theme.spacing(10),
    },
  },
  buttonContainer: {
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    }
  },
  button: {
    letterSpacing: '1px',
    fontSize: '16px',
    fontWeight: 400,
    marginTop: theme.spacing(4),
    padding: theme.spacing(1, 6),
    borderRadius: '25px',
    backgroundColor: '#90acd3',
    '& span': { color: '#FFF' },
    '&:hover': { backgroundColor: '#25557c' }
  },
  
}))

const Home = () => {

  const classes = useStyles()
  const [activeNav, setActiveNav] = useState('Home')

  
  const homeRef = useRef()
  const servicesRef = useRef()
  const aboutMeRef = useRef()
  const projectsRef = useRef()

  useEffect(() => {
    const typingTextAnimation = document.querySelector('#typingTextAnimation')
    init(typingTextAnimation, {
      loop: true,
      showCursor: false,
      strings: [
        'I\'m a full stack developer',
        'I am a Freelancer',
        'Lets build something great!',
      ]
    })
  }, [])

  
  const scrollTo = (ref, nav) => () => {
    setActiveNav(nav)
    ref?.current && ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  }

  const navConfig = [
    { label: 'Home', onClick: scrollTo(homeRef, 'Home') },
    { label: 'My Tech Stack', onClick: scrollTo(servicesRef, 'My Tech Stack') },
    { label: 'Projects', onClick: scrollTo(projectsRef, 'Works') },
    { label: 'About Me', onClick: scrollTo(aboutMeRef, 'About Me') },
  ]

  return (
    <div className={classes.root}>
      <HorizontalNavBar navConfig={navConfig} activeNav={activeNav} />
      
      <div ref={homeRef} />
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12} sm={12} md={6}>
          <Typography className={clsx(classes.text, classes.smallText)}>
            Hey! I am
          </Typography>
          <Typography variant="h3" className={classes.text} style={{ fontWeight: 'bold' }}>
            Kayee
          </Typography>
          <div style={{ minHeight: 50 }}>
          <Typography variant="h4" className={classes.text} id="typingTextAnimation">            
          </Typography>
          </div>
          {/* <Typography variant="body1" className={classes.text}>
            Dorem ipsum dolor sitamet, consectetur adipiscing elit, sed do eiusm tempor incididunt ulabore et dolore magna aliqua. 
          </Typography> */}
          <div className={classes.buttonContainer}>
            <Button
              color="primary"
              variant="contained"
              className={classes.button}
              target="_top"
              rel="noopener noreferrer"
              href={`mailto:kayeeee.wong@gmail.com`}
              style={{ textTransform: 'capitalize' }} 
            >
              Say Hi! 👋
            </Button>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} style={{ textAlign: 'center' }}>
          <img className={classes.image} src="images/kayee.png" alt="Kayee" />
        </Grid>
      </Grid>
      
      <div ref={servicesRef} />
      <Services />

      <div ref={projectsRef} />
      <Projects />

      <div ref={aboutMeRef} />
      <AboutMe />

      <Footer />
    </div>
  )
}

export default Home
