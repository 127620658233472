import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardContent,
  CardHeader
} from '@material-ui/core'


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    // marginTop: theme.spacing(-4),
    marginBottom: theme.spacing(1),
    // background: theme.palette.background.paper,
    borderRadius: '4px',
  },
}))

const SectionCard = ({ title, titleVariant, children, className }) => {
  const classes = useStyles()

  return (
    <Card className={clsx(classes.root, className? className : '')}>
      {title && (
        <CardHeader
          title={title}
          titleTypographyProps={{ variant: titleVariant ? titleVariant : 'h6' }}
        />
      )}
      
      <CardContent className={classes.content}>
        {children && children}
      </CardContent>
    </Card>
  )
}

SectionCard.propTypes = {
  title: PropTypes.string,
  titleVariant: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
}

export default SectionCard
