import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, TextField, Grid } from '@material-ui/core'
import { Loader } from 'components'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  container: {
    padding: '40px 20px'
  },
  tableContainer: {
    maxHeight: 440,
  },
  link: {
    color: theme.palette.text.link,
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  searchLabel: {
    padding: '10px 0 20px 0',
  }
}));


function clean(obj, hideColumns) {
  hideColumns.forEach(column => {
    delete obj[column]
  });
  return obj
}

const DynamicObjectListTable = ({ data, hideColumns = [], flag, usePagination, onEdit, onSearchChange }) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) =>
    setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const rows = data.map(item => clean(item, hideColumns))

  return (
    <Paper className={classes.root}>
      <div className={classes.container}>

        {flag && flag.inProgress ? (
          <Loader />
        ): (
          <>
            {onSearchChange && rows.length > 0 && (
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <Typography variant="body1" align="left" className={classes.searchLabel} style={{ marginLeft: 10 }}>
                    Showing {rowsPerPage} entries:
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" align="right" className={classes.searchLabel}>
                    Search:
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    id="outlined-basic"
                    placeholder="Search..."
                    variant="outlined"
                    fullWidth
                    size="small"
                    className={classes.textField}
                    onChange={(e) => onSearchChange(e.target.value)}
                  />
                </Grid>
              </Grid>
            )}
            
            {rows.length === 0 && (
              <Typography variant="body1" align="center" className={classes.searchLabel} style={{ margin: '10px 0 0 0' }}>
                No records available.
              </Typography>
            )}
            
            <TableContainer className={classes.tableContainer}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>                    
                    {rows[0] && Object.entries(rows[0]).map(([key, value], headerIndex) => 
                      <TableCell key={`table-heading-${headerIndex}`} className={classes.tableHeader}>
                        <strong>{key}</strong>
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {(usePagination ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map((rowData, rowIndex) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={`row-${rowIndex}`}>
                      {Object.entries(rowData).map(([key, value], cellIndex) => (
                        <TableCell align="left" key={`cell-${cellIndex}`}>
                          {cellIndex === 0 && onEdit ? (
                            <span className={classes.link} onClick={onEdit(value)}>
                              {value}
                            </span>
                          ) : key === 'Date' ? moment(value).format('d MMM YY') : value || '-'}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>

              </Table>
            </TableContainer>

            {usePagination && rows.length > 0 && (
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            )}
          </>
        )}

      </div>
    </Paper>
  );
}

DynamicObjectListTable.propTypes = {
  data: PropTypes.array.isRequired,
  hideColumns: PropTypes.array,
  flag: PropTypes.string,
  usePagination: PropTypes.bool,
  // func
  onEdit: PropTypes.func,
  onSearchChange: PropTypes.func,
}

export default DynamicObjectListTable