import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Card, CardContent, Button, colors, Typography, Badge } from '@material-ui/core'
import { LightboxGallery, Dialog, RowValueText, Label } from 'components'


const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(4, 2)
  },
  dialog: {
    borderRadius: '25px',    
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
    }
  },
  card: {
    borderRadius: '25px',
  },
  list: {
    margin: theme.spacing(0, 0, 0, -3),
  },
  imageContainer: {
    overflow: 'hidden',
    maxWidth: '300px',
    maxHeight: '250px',
    borderRadius: '20px',
    margin: theme.spacing(3),
    boxShadow: '0 10px 15px #a8b4fc30',
  },
  image: {
    width: '300px',
    height: '250px',
    objectFit: 'cover',
    transition: 'transform .5s ease',
    '&:hover': {
      cursor: 'pointer',
      [theme.breakpoints.up('sm')]: {
        transform: 'scale(1.5)',
      },
    },
  },
  button: {
    letterSpacing: '1px',
    fontSize: '16px',
    fontWeight: 400,
    marginTop: theme.spacing(4),
    padding: theme.spacing(1, 6),
    borderRadius: '25px',
    backgroundColor: '#90acd3',
    '& span': { color: '#FFF' },
    '&:hover': { backgroundColor: '#25557c' }
  },
  center: {    
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  badge: {
    backgroundColor: colors.green[600],//'#90acd3',
    color: '#FFF',
    padding: '5px 10px'
  }
}))

const Gallery = ({ projectTitle, shortDescription, description, status, duration, responsibilities, stack, src, link, screenshots }) => {
  
  const classes = useStyles()
  const [openModal, setOpenModal] = useState(false)

  const onClose = () => setOpenModal(false)

  const getStatusColor = (status) =>
    status === 'Ongoing' ? colors.green[600] : status === 'No longer on the project' ? colors.orange[600] : colors.red[600]

  return (
    <>
      <div className={classes.imageContainer}>
        <img
          className={classes.image}
          src={src}
          alt={projectTitle}
          onClick={() => setOpenModal(true)}
        />

        <Dialog
          autoSize
          open={openModal}
          classes={{ paper: classes.dialog }}
          aria-labelledby={projectTitle}
          title={projectTitle}
          subtitle={shortDescription}
          onClose={onClose}>
          <Card classes={{ root: classes.card }}>
            <CardContent>
              <Grid container spacing={2} className={classes.container}>

                {description && (
                  <Grid item xs={12}>
                    <RowValueText label="About" responsive responsiveLabel>
                      {description}
                    </RowValueText>
                  </Grid>
                )}

                {status && (
                  <Grid item xs={12}>
                    <RowValueText label="Status" responsive responsiveLabel>
                      <Label
                        color={getStatusColor(status)}
                        variant="outlined">
                        {status}
                      </Label>
                    </RowValueText>
                  </Grid>
                )}

                {duration && (
                  <Grid item xs={12}>
                    <RowValueText label="Project Duration" responsive responsiveLabel>
                      {duration}
                    </RowValueText>
                  </Grid>
                )}

                {responsibilities && (
                  <Grid item xs={12}>
                    <RowValueText label="Responsibilities" responsive responsiveLabel>
                      <ul className={classes.list}>
                        {responsibilities.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </RowValueText>
                  </Grid>
                )}

                {stack && stack.length> 0 && (
                  <Grid item xs={12}>
                    <RowValueText label="Technologies" responsive responsiveLabel>
                      <ul className={classes.list}>
                        {stack.map((tech, index) => (
                          <li key={index}>{tech}</li>
                        ))}
                      </ul>
                    </RowValueText>
                  </Grid>
                )}

                {screenshots && screenshots.length> 0 && (
                  <Grid item xs={12}>
                    <RowValueText label="Screenshots" responsive responsiveLabel>
                      <LightboxGallery attachments={screenshots} />
                    </RowValueText>
                  </Grid>
                )}

                {link && (
                  <Grid item xs={12}>
                    <RowValueText label="Link" responsive responsiveLabel>  
                      <a target="_top" rel="noopener noreferrer" href={link || ''}>
                        View Project
                      </a>
                    </RowValueText>
                  </Grid>
                )}
              </Grid>

            </CardContent>     
          </Card>

          <div className={classes.center}>
            <Button variant="contained" className={classes.button} onClick={onClose}>
              Close
            </Button>
          </div>
        </Dialog>
      </div>
      <Typography variant="body1">
        {projectTitle}
      </Typography>
      {status === 'Ongoing' && (
        <Badge badgeContent="Ongoing" classes={{ badge: classes.badge }} />
      )}
    </>
  )
}

export default Gallery
