import React from 'react'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
// import { __RouterContext } from 'react-router'
import clsx from 'clsx'
import { Avatar, IconButton, List, ListItem, ListItemText, Toolbar, AppBar, SwipeableDrawer  } from '@material-ui/core'

 // Icons
import MenuIcon from '@material-ui/icons/Menu'

// const useRouter = () => useContext(__RouterContext)

// const getInitials = (name = '') => name
//  .replace(/\s+/, ' ')
//  .split(' ')
//  .slice(0, 2)
//  .map(v => v && v[0].toUpperCase())
//  .join('')

const drawerWidth = 240
const useStyles = makeStyles(theme => ({
  root: {},
  logo: {
    color: '#FFF', // theme.palette.getContrastText('#90acd3'),
    backgroundColor: '#90acd3',
    '&:hover': { backgroundColor: '#25557c', cursor: 'pointer' }
  },
  appBar: {
    boxShadow: '0 0 2rem 0 rgb(33 37 41 / 10%)',
    color: '#000000',
    backgroundColor: theme.palette.common.white, 
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#f7f7fc',
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  toggleMenuIcon: {
    cursor: 'pointer',
    fontSize: 35,
    color: '#495057',
    '&:hover': {
      color: '#3b7ddd',
    },
  }, 
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    paddingTop: 5,
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  myaccount: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  navLink: {
    whiteSpace: 'nowrap',
    color: theme.palette.white,
    '&:hover': {
      color: '#90acd3',
      cursor: 'pointer',
      // borderColor: '#90acd3',
      // borderBottom: '2px solid',
      width: 'auto',
    },
  },
  activeNav: {
    color: '#90acd3',
    cursor: 'pointer',
    borderColor: '#90acd3',
    borderBottom: '2px solid',
    width: 'auto',
  },
  // for mobile nav bar
  list: {
    width: 250,
  },
}))

const HorizontalNavBar = ({ navConfig, activeNav }) => {  

  const classes = useStyles()

  const [showMobileMenu, setShowMobileMenu] = React.useState(false)
  
  const list = () => (
    <div
      anchor="right"
      className={classes.list}
      role="presentation"
      onClick={() => setShowMobileMenu(false)}
      onKeyDown={() => setShowMobileMenu(false)}
    >
      <List>
        {navConfig.map(({ label, onClick }) =>  (
          <ListItem button key={`mobile-nav-item-${label}`} onClick={onClick} >
            <ListItemText primary={label} />
          </ListItem>
        ))}
      </List>
    </div>
  )


  return (
    <AppBar
      // elevation={0.9}
      position="fixed"
      className={classes.appBar}
    >
      <Toolbar>
        <Avatar
          className={classes.logo}
          alt={'Kayee'}
          // src="/images/avatar.jpg"
          onClick={navConfig[0].onClick}
        >
          K
        </Avatar>

        <div className={classes.grow} />

        <div className={classes.sectionDesktop}>
          {navConfig.map(({ label, onClick }, key) =>  (
            <ListItem
              key={`desktop-nav-item-${label}`}
              className={clsx(classes.navLink, activeNav === label ? classes.activeNav : '')}
              onClick={onClick}
            >
              <ListItemText primary={label} />
            </ListItem>
          ))}
        </div>

        <div className={classes.sectionMobile}>
          <IconButton
            aria-controls="mobile-menu"
            aria-haspopup="true"
            color="inherit"
            onClick={() => setShowMobileMenu(true)}>
            <MenuIcon className={classes.menuIcon} />
          </IconButton>
         
          <SwipeableDrawer
            open={showMobileMenu}
            onClose={() => setShowMobileMenu(false)}
            onOpen={() => setShowMobileMenu(true)}
          >
            {list()}
          </SwipeableDrawer>
        </div>
      </Toolbar>
    </AppBar>
  )
}

HorizontalNavBar.propTypes = {
  openSideMenu: PropTypes.bool,
  navConfig: PropTypes.array.isRequired,
}

export default HorizontalNavBar