import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Typography, Container, Tabs, Tab } from '@material-ui/core'

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #3f51b5',
  },
  indicator: {
    backgroundColor: '#3f51b5',
  },
})(Tabs)

const AntTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#3f51b5',
      opacity: 1,
    },
    '&$selected': {
      color: '#3f51b5',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#3f51b5',
    },
  },
  selected: {},
}))(props => <Tab disableRipple {...props} />)

const useStyles = makeStyles(theme => ({
  root: {
  },
  tabs: {
    marginLeft: theme.spacing(-1)
  },
  content: {
    padding: theme.spacing(3, 0)
  }
}))

const CustomTabs = ({ tabs, setTab, children }) => {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
    setTab && setTab(newValue)
  }

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <AntTabs value={value} onChange={handleChange} aria-label="ant tabs" className={classes.tabs}>
          {tabs && tabs.map((label, key) => 
            <AntTab key={key} label={label} />
          )}
        </AntTabs>
        <Typography className={classes.padding} />
        <div className={classes.content}>
          {children}
        </div>
      </Container>
    </div>
  )
}

CustomTabs.propTypes = {
  tabs: PropTypes.array,
  setTab: PropTypes.func,
  children: PropTypes.any,
}

export default CustomTabs