import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { InputLabel, MenuItem, FormControl, Select } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 200,
    // width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

const Dropdown = ({ fullWidth, value, setValue, options, label }) => {
  const classes = useStyles()

  const inputLabel = React.useRef(null)
  const [labelWidth, setLabelWidth] = React.useState(value && value.length)

  React.useEffect(() => setLabelWidth(inputLabel.current.offsetWidth), [])

  const handleChange = event => setValue(event.target.value)

  return (
    <FormControl fullWidth={fullWidth} variant="outlined" className={classes.formControl}>
      <InputLabel ref={inputLabel} id="select-outlined-label">
        {label}
      </InputLabel>
      <Select
        labelId="select-outlined-label"
        id="select-outlined"
        value={value}
        onChange={handleChange}
        labelWidth={labelWidth}
      >
        {options && options.map((item, key) =>
          <MenuItem key={key} value={item.value ? item.value : item}>
            {item.label ? item.label : item}
          </MenuItem>
        )}
      </Select>
    </FormControl>
  )
}

Dropdown.propTypes = {
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.func,
  options: PropTypes.array,
}

export default Dropdown
